import React, { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { LiveProvider, LiveEditor, LivePreview, LiveError } from 'react-live';
import ImageUploader from './ImageUploader';

const CodeManager = () => {
  const [userMessage, setUserMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [files, setFiles] = useState([]);
  const [versions, setVersions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [latestCode, setLatestCode] = useState('');
  const [latestExplanation, setLatestExplanation] = useState('');

  const [showNewPageModal, setShowNewPageModal] = useState(false);
  const [newPagePrompt, setNewPagePrompt] = useState('');
  const [latestPageCode, setLatestPageCode] = useState('');
  const [latestPageExplanation, setLatestPageExplanation] = useState('');

  const [previewMode, setPreviewMode] = useState('desktop');
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [visionReply, setVisionReply] = useState('');

  // For resizing chat/code panels
  const [chatWidth, setChatWidth] = useState(50);
  const [isDragging, setIsDragging] = useState(false);

  // CMS form fields
  const [brandName, setBrandName] = useState('MyBusiness');
  const [heroTitle, setHeroTitle] = useState('Welcome to a World of Creativity');
  const [heroSubtitle, setHeroSubtitle] = useState('We bring your ideas to life with innovative designs and exceptional craftsmanship.');
  const [aboutText, setAboutText] = useState('We specialize in delivering top-notch solutions that drive digital innovation.');
  
  useEffect(() => {
    fetchFiles();
    fetchVersions();
  }, []);

  async function fetchFiles() {
    const res = await fetch('/api/list-files');
    const data = await res.json();
    if (data.files) {
      setFiles(data.files);
    }
  }

  async function fetchVersions() {
    const res = await fetch('/api/list-versions');
    const data = await res.json();
    if (data.versions) {
      setVersions(data.versions);
    }
  }

  // Send message (either text only or text+image)
  async function sendMessage() {
    if (!userMessage.trim() && !uploadedImageUrl) return; // no empty message

    // Add user message to chat
    const newUserMessage = { role: 'user', content: userMessage };
    setChatHistory((prev) => [...prev, newUserMessage]);

    let endpoint;
    let body;

    if (uploadedImageUrl) {
      // Send image + text
      endpoint = '/api/chat-with-image';
      body = { prompt: userMessage, imageUrl: uploadedImageUrl };
    } else {
      // Send text only
      endpoint = '/generate-code';
      body = { prompt: userMessage };
    }

    try {
      const res = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });

      const data = await res.json();

      if (data.error) {
        // If there's an error, show it in chat
        const errorAssistantMessage = {
          role: 'assistant',
          content: { explanation: data.error }
        };
        setChatHistory((prev) => [...prev, errorAssistantMessage]);
        setLatestCode('');
        setLatestExplanation(data.error);
      } else {
        // We have explanation and code
        const explanation = data.explanation || 'No explanation provided.';
        const code = data.code || '';

        // Add assistant message with explanation only to chat
        const assistantMessage = {
          role: 'assistant',
          content: {
            explanation: explanation
          }
        };
        setChatHistory((prev) => [...prev, assistantMessage]);

        setLatestExplanation(explanation);
        setLatestCode(code);
      }

    } catch (err) {
      console.error('Error sending message:', err);
      const errorAssistantMessage = {
        role: 'assistant',
        content: { explanation: 'An error occurred while communicating with the server.' }
      };
      setChatHistory((prev) => [...prev, errorAssistantMessage]);
      setLatestCode('');
      setLatestExplanation('An error occurred.');
    } finally {
      // Always clear message and image after send
      setUserMessage('');
      setUploadedImageUrl('');
      setVisionReply('');
    }
  }

  async function createNewPage() {
    if (!newPagePrompt.trim()) return;
    const res = await fetch('/generate-code', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ prompt: newPagePrompt })
    });

    let data;
    try {
      data = await res.json();
    } catch (err) {
      alert('Error receiving JSON from model.');
      return;
    }

    if (!data.code) {
      alert('No code received from the model. Please try again.');
      return;
    }

    setLatestPageExplanation(data.explanation || '');
    setLatestPageCode(data.code || '');
    setShowNewPageModal(false);
    setNewPagePrompt('');

    const assistantMessage = {
      role: 'assistant',
      content: {
        explanation: data.explanation || ''
      }
    };
    setChatHistory((prev) => [...prev, { role:'user', content:newPagePrompt }, assistantMessage]);
  }

  async function applyChanges() {
    if (!latestCode) {
      alert('No code available to apply.');
      return;
    }
    const res = await fetch('/api/deploy', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: latestCode, type: 'frontend' })
    });
    const data = await res.json();
    if (data.status === 'deployed') {
      alert('Your changes have been applied successfully!');
      fetchFiles();
      fetchVersions();
      setPreviewUrl('https://the-user-domain.com');
    } else {
      alert(`Failed to apply changes: ${data.error || 'Unknown error'}`);
    }
  }

  async function deployNewPage() {
    if (!latestPageCode) {
      alert('No page code available to deploy.');
      return;
    }
    const res = await fetch('/api/deploy', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ code: latestPageCode, type: 'frontend' })
    });
    const data = await res.json();
    if (data.status === 'deployed') {
      alert('Your new page has been deployed successfully!');
      fetchFiles();
      fetchVersions();
      setPreviewUrl('https://the-user-domain.com');
    } else {
      alert(`Failed to deploy page: ${data.error || 'Unknown error'}`);
    }
  }

  async function revertVersion(versionId) {
    const res = await fetch('/api/revert-version', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ versionId })
    });
    const data = await res.json();
    if (data.status === 'reverted') {
      alert('Reverted to the selected version!');
      fetchFiles();
      fetchVersions();
    } else {
      alert(`Failed to revert: ${data.error || 'Unknown error'}`);
    }
  }

  async function loadFileContent(fileName) {
    const res = await fetch('/api/get-file', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fileName })
    });
    const data = await res.json();
    if (data.content) {
      const assistantMessage = {
        role: 'assistant',
        content: {
          explanation: `Loaded existing file: ${fileName}`
        }
      };
      setChatHistory((prev) => [...prev, assistantMessage]);
      setLatestExplanation(`Editing existing file: ${fileName}`);
      setLatestCode(data.content);
      setSelectedFile(fileName);
    } else {
      alert('Failed to load file content.');
    }
  }

  const previewWidths = {
    mobile: '375px',
    tablet: '768px',
    desktop: '1024px'
  };

  // Remove imports from code for react-live
  const previewCode = latestCode
    .split('\n')
    .filter(line => !line.trim().startsWith('import'))
    .join('\n');

  // Handle resizing
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      const container = document.getElementById('code-manager-container');
      if (!container) return;
      const containerWidth = container.offsetWidth;
      const newChatWidth = (e.clientX / containerWidth) * 100;
      if (newChatWidth > 10 && newChatWidth < 90) {
        setChatWidth(newChatWidth);
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  // Generate code from the simple CMS config fields
  function generateCodeFromConfig() {
    const code = `
const siteConfig = {
  brandName: "${brandName}",
  navigation: [
    { label: "Home", href: "#home" },
    { label: "About", href: "#about" },
    { label: "Services", href: "#services" },
    { label: "Contact", href: "#contact" }
  ],
  hero: {
    backgroundImage: "/path/to/hero-image.jpg",
    title: "${heroTitle}",
    subtitle: "${heroSubtitle}",
    cta: {
      text: "Discover More",
      href: "#services"
    }
  },
  about: {
    title: "About Us",
    text: "${aboutText}"
  },
  services: {
    title: "Our Services",
    items: [
      {
        title: "Web Development",
        description: "Creating dynamic and responsive websites tailored to your requirements."
      },
      {
        title: "UI/UX Design",
        description: "Ensuring an intuitive and pleasing experience for your users."
      },
      {
        title: "SEO Optimization",
        description: "Boosting your site's visibility on search engines for higher traffic."
      }
    ]
  },
  footer: {
    text: "� 2023 ${brandName}. All Rights Reserved."
  }
};

const MyComponent = () => {
  return (
    <div className="font-sans antialiased bg-gray-100 text-gray-900">
      <header className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white shadow p-4">
        <nav className="container mx-auto flex justify-between items-center">
          <h1 className="text-lg font-bold">{siteConfig.brandName}</h1>
          <ul className="flex space-x-4">
            {siteConfig.navigation.map((navItem, idx) => (
              <li key={idx}>
                <a href={navItem.href} className="hover:text-indigo-200">
                  {navItem.label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <section
        id="home"
        className="h-screen bg-cover bg-center flex justify-center items-center"
        style={{ backgroundImage: \`url(\${siteConfig.hero.backgroundImage})\` }}
      >
        <div className="text-center text-white px-4">
          <h2 className="text-4xl md:text-5xl font-bold mb-4">{siteConfig.hero.title}</h2>
          <p className="text-lg mb-6">{siteConfig.hero.subtitle}</p>
          <a
            href={siteConfig.hero.cta.href}
            className="bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded shadow"
          >
            {siteConfig.hero.cta.text}
          </a>
        </div>
      </section>

      <section id="about" className="py-16 bg-gray-200">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-6">{siteConfig.about.title}</h2>
          <p className="leading-relaxed text-gray-700 text-center max-w-2xl mx-auto">
            {siteConfig.about.text}
          </p>
        </div>
      </section>

      <section id="services" className="py-16 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">{siteConfig.services.title}</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {siteConfig.services.items.map((service, idx) => (
              <div key={idx} className="bg-white p-6 rounded-lg shadow hover:shadow-lg">
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-700">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <footer className="bg-gray-800 text-gray-400 py-6">
        <div className="container mx-auto text-center">
          <p>{siteConfig.footer.text}</p>
        </div>
      </footer>
    </div>
  );
};

render(<MyComponent />);
`;

    setLatestCode(code);
    setLatestExplanation("Generated code from CMS config.");
    const assistantMessage = {
      role: 'assistant',
      content: {
        explanation: "Generated code from CMS config."
      }
    };
    setChatHistory((prev) => [...prev, assistantMessage]);
  }

  return (
    <div className="h-screen flex flex-col" id="code-manager-container">
      {showNewPageModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-4 rounded shadow max-w-md w-full">
            <h2 className="text-xl font-bold mb-2">Create a New Page</h2>
            <p className="text-gray-700 mb-2">Describe what this page should look like:</p>
            <textarea
              className="border w-full p-2 mb-4"
              rows={4}
              value={newPagePrompt}
              onChange={(e) => setNewPagePrompt(e.target.value)}
            />
            <div className="flex justify-end space-x-2">
              <button
                className="p-2 bg-gray-300 rounded"
                onClick={() => {
                  setShowNewPageModal(false);
                  setNewPagePrompt('');
                }}
              >
                Cancel
              </button>
              <button
                className="p-2 bg-blue-500 text-white rounded"
                onClick={createNewPage}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex-1 flex overflow-hidden">
        {/* Chat Panel */}
        <div className="flex-shrink-0" style={{ width: `${chatWidth}%` }}>
          <div className="flex flex-col h-full bg-gray-100">
            <div className="flex-1 overflow-auto p-4">
              {chatHistory.length === 0 && (
                <p className="text-gray-600 mb-4">Start by typing a prompt or uploading an image below.</p>
              )}
              {chatHistory.map((msg, idx) => (
                <div key={idx} className={`mb-4 max-w-lg ${msg.role === 'user' ? 'ml-auto' : ''}`}>
                  <div className={
                    msg.role === 'user'
                      ? 'bg-blue-100 text-gray-800 p-2 rounded-lg'
                      : 'bg-gray-50 p-2 rounded-lg border text-gray-800'
                  }>
                    <strong>{msg.role === 'user' ? 'You' : 'AI'}:</strong>
                    {msg.content.explanation && (
                      <p className="whitespace-pre-wrap mt-1">{msg.content.explanation}</p>
                    )}
                    {!msg.content.explanation && msg.content && (
                      <p className="whitespace-pre-wrap mt-1">{msg.content}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="border-t p-2 bg-white flex items-center space-x-2">
              {uploadedImageUrl && (
                <div className="relative">
                  <img src={uploadedImageUrl} alt="Uploaded" className="w-16 h-16 object-cover rounded" />
                  <button
                    className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full px-1"
                    onClick={() => setUploadedImageUrl('')}
                  >x</button>
                </div>
              )}
              {!uploadedImageUrl && (
                <ImageUploader onImageUploaded={(url) => setUploadedImageUrl(url)} />
              )}
              <input
                className="border p-2 flex-1"
                type="text"
                placeholder="Type a message..."
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') sendMessage();
                }}
              />
              <button className="p-2 bg-blue-500 text-white rounded" onClick={sendMessage}>
                Send
              </button>
            </div>
          </div>
        </div>

        {/* Divider */}
        <div
          onMouseDown={() => setIsDragging(true)}
          className="w-1 bg-gray-300 cursor-col-resize"
        />

        {/* Code Panel */}
        <div className="flex-1 flex flex-col overflow-auto bg-white p-4">
          <h3 className="font-bold text-xl">Simple CMS Editor</h3>
          <p className="text-gray-600 text-sm mb-4">
            Fill in these fields and click "Generate Code from Config" to create a website. This makes it easy for anyone to edit without coding.
          </p>
          <div className="mb-4 space-y-2">
            <div>
              <label className="block text-sm font-bold mb-1">Brand Name:</label>
              <input
                className="border w-full p-2"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Hero Title:</label>
              <input
                className="border w-full p-2"
                value={heroTitle}
                onChange={(e) => setHeroTitle(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">Hero Subtitle:</label>
              <input
                className="border w-full p-2"
                value={heroSubtitle}
                onChange={(e) => setHeroSubtitle(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-bold mb-1">About Text:</label>
              <textarea
                className="border w-full p-2"
                rows={3}
                value={aboutText}
                onChange={(e) => setAboutText(e.target.value)}
              />
            </div>
            <button
              className="p-2 bg-green-500 text-white rounded mt-2"
              onClick={generateCodeFromConfig}
            >
              Generate Code from Config
            </button>
          </div>

          <h3 className="font-bold text-xl mt-8">Latest Generated Code</h3>
          <p className="text-gray-600 text-sm mb-2">
            Below is the code proposed by the assistant. You can apply these changes or deploy if it's a new page.
          </p>

          {latestCode ? (
            <>
              <div className="mb-4">
                <strong>Proposed Code:</strong>
                <div className="bg-gray-900 text-white text-sm rounded p-2 overflow-auto mt-2">
                  <SyntaxHighlighter language="jsx" style={vscDarkPlus}>
                    {latestCode}
                  </SyntaxHighlighter>
                </div>
              </div>
              <div className="flex space-x-4">
                <div>
                  <h3 className="font-bold text-lg mb-2">Apply Changes</h3>
                  <p className="text-gray-600 text-sm mb-2">
                    Click below to apply the latest code changes.
                  </p>
                  <button
                    className="p-2 bg-green-500 text-white rounded"
                    onClick={applyChanges}
                  >
                    Apply Latest Code
                  </button>
                </div>

                <div>
                  <h3 className="font-bold text-lg mb-2">Deploy New Page</h3>
                  <p className="text-gray-600 text-sm mb-2">
                    If this code represents a new page, deploy it to your site.
                  </p>
                  <button
                    className="p-2 bg-green-500 text-white rounded"
                    onClick={deployNewPage}
                    disabled={!latestPageCode}
                  >
                    Deploy Page
                  </button>
                </div>
              </div>

              <h3 className="font-bold text-xl mt-4">Live Preview</h3>
              <p className="text-gray-600 text-sm">
                Preview your code below. Switch between different device sizes.
              </p>
              <div className="flex space-x-2 mb-2 mt-2">
                <button
                  className={`p-2 rounded ${previewMode === 'mobile' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                  onClick={() => setPreviewMode('mobile')}
                >
                  Mobile
                </button>
                <button
                  className={`p-2 rounded ${previewMode === 'tablet' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                  onClick={() => setPreviewMode('tablet')}
                >
                  Tablet
                </button>
                <button
                  className={`p-2 rounded ${previewMode === 'desktop' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                  onClick={() => setPreviewMode('desktop')}
                >
                  Desktop
                </button>
              </div>

              <button
                className="p-2 bg-blue-500 text-white rounded"
                onClick={() => setIsFullScreen(true)}
              >
                Full Screen Preview
              </button>

              {isFullScreen && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center z-50 p-4">
                  <div className="bg-white rounded overflow-auto relative w-full h-full max-w-5xl max-h-full shadow-lg">
                    <div className="p-2 border-b flex justify-between items-center">
                      <span className="font-bold">Full Screen Preview</span>
                      <button
                        className="p-2 bg-red-500 text-white rounded"
                        onClick={() => setIsFullScreen(false)}
                      >
                        Close Fullscreen
                      </button>
                    </div>
                    <div className="p-4 overflow-auto" style={{ height: 'calc(100% - 3rem)' }}>
                      <div style={{ width: '100%', border: '1px solid #ccc', padding: '10px', margin: 'auto' }}>
                        <LiveProvider code={previewCode} noInline={true}>
                          <LivePreview className="mb-2" />
                          <LiveError className="text-red-500 text-sm" />
                        </LiveProvider>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <LiveProvider code={previewCode} noInline={true}>
                <div className="border p-2 bg-white mt-4">
                  <h4 className="font-bold mb-2">Preview ({previewMode})</h4>
                  <div style={{ width: previewWidths[previewMode], border: '1px solid #ccc', margin: 'auto', padding: '10px' }}>
                    <LivePreview className="mb-2" />
                    <LiveError className="text-red-500 text-sm" />
                  </div>

                  <h4 className="font-bold mb-2 mt-4">Code Editor</h4>
                  <LiveEditor className="border p-2 bg-gray-100 text-sm h-64 overflow-auto" style={{ fontFamily: 'monospace' }} />
                </div>
              </LiveProvider>
            </>
          ) : (
            <p className="text-gray-500">No code available yet. Generate code from the CMS form or interact with the chat to generate code.</p>
          )}

          {previewUrl && (
            <div className="mt-4">
              <h4 className="font-bold mb-1">View Your Site</h4>
              <a href={previewUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                {previewUrl}
              </a>
            </div>
          )}

          <div className="mt-4 flex space-x-4">
            <div className="flex-1 bg-white border rounded p-4 h-48 overflow-auto">
              <h3 className="font-bold mb-2">Your Files</h3>
              {files.length === 0 && <p className="text-gray-500 text-sm">No files yet.</p>}
              <ul className="space-y-1">
                {files.map(f => (
                  <li key={f}>
                    <button
                      onClick={() => loadFileContent(f)}
                      className={`block w-full text-left px-2 py-1 hover:bg-gray-100 ${
                        selectedFile === f ? 'bg-blue-100' : ''
                      }`}
                    >
                      {f}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            <div className="w-1/2 bg-white border rounded p-4 h-48 overflow-auto">
              <h3 className="font-bold mb-2">Version History</h3>
              {versions.length === 0 && <p className="text-gray-500 text-sm">No versions yet.</p>}
              <ul className="space-y-1">
                {versions.map(v => (
                  <li key={v.id} className="flex justify-between items-center">
                    <span className="text-sm text-gray-700">{v.label}</span>
                    <button
                      onClick={() => revertVersion(v.id)}
                      className="text-blue-500 hover:underline text-sm"
                    >
                      Revert
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeManager;
