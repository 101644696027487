import React from 'react';

const Sidebar = ({ activeSection, setActiveSection }) => {
  const items = [
    { key: 'infrastructure', label: 'Infrastructure' },
    { key: 'site-structure', label: 'Site Structure' },
    { key: 'code', label: 'Code Manager' },
    { key: 'pages', label: 'Page Manager' },
    { key: 'branding', label: 'Branding' },
  ];

  return (
    <div className="w-64 bg-white border-r flex flex-col">
      <div className="p-4 border-b flex justify-between items-center">
        <span className="font-bold text-gray-700">Menu</span>
      </div>
      <div className="flex-1 overflow-auto">
        {items.map(item => (
          <button
            key={item.key}
            onClick={() => setActiveSection(item.key)}
            className={`block w-full text-left px-4 py-2 hover:bg-gray-100 ${
              activeSection === item.key ? 'bg-blue-100 text-blue-700' : 'text-gray-700'
            }`}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
